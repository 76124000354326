import {
    ON_BOOKING_CONFIRM_ERROR,
    ON_BOOKING_CONFIRM_FIELDS_ERROR,
    ON_BOOKING_CONFIRM_SUCCESS,
    ON_CLEAR_ERROR_CODES,
    ON_MAINTENANCE_MODE,
    ON_SALON_GROUP_NOT_FOUND,
    ON_SET_ERROR_CODE,
    ON_SUBMITTING_BOOKING,
    RESET_STATE,
} from '../actions/type'

const INITIAL_STATE = {
    submitting: false,
    booking: null,
    errorCode: null,
    errorFields: undefined,
}

const bookingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ON_BOOKING_CONFIRM_SUCCESS:
            return {
                ...state,
                booking: action.payload.booking,
                submitting: false,
            }
        case ON_BOOKING_CONFIRM_ERROR:
            return {
                ...state,
                errorCode: action.payload.data.code,
                submitting: false,
            }
        case ON_BOOKING_CONFIRM_FIELDS_ERROR:
            return {
                ...state,
                submitting: false,
                errorFields: action.payload,
            }
        case ON_SUBMITTING_BOOKING:
            return {
                ...state,
                submitting: action.payload,
            }
        case ON_SET_ERROR_CODE:
            return {
                ...state,
                errorCode: action.payload,
            }
        case ON_MAINTENANCE_MODE:
            return {
                ...state,
                errorCode: 503,
            }
        case ON_SALON_GROUP_NOT_FOUND:
            return {
                ...state,
                errorCode: action.payload ? 404 : null,
            }
        case ON_CLEAR_ERROR_CODES:
            return {
                ...state,
                errorCode: null,
            }
        case RESET_STATE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default bookingReducer
