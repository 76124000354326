import React from 'react';
import ReactPageComponent, {connect} from '../components/ReactPageComponent';
import {withTranslation} from 'react-i18next';
import {getCategories, onCategoriesSelect} from '../actions/categories';
import {setActiveProgress, setSalonName, setTransGroup} from '../actions/userInterface';
import {onSearch} from '../actions/search';
import {addServiceToOrder, getServices} from '../actions/order';
import ServiceItems from '../components/ServiceItems';
import Loading from '../components/Loading';
import {Redirect} from 'react-router-dom';
import {setParamsInitialized} from "../actions/widget";

class Services extends ReactPageComponent {
    unmounted = false;
    state = {
        loading: true,
    };

    scrollPosKey = 'Services';

    componentDidMount() {
        super.componentDidMount();

        this.props.setTransGroup('services');
        this.props.setActiveProgress(2);
        this.props.onSearch('');

        if (!this.props.settings['my_salon_group.services.show.categories']) {
            this.props.getCategories().then(() => {
                this.props.getServices().then(() => {
                    if (!this.unmounted) {
                        this.setState({
                            loading: false,
                        });

                        if (this.props.widget.client_web && !this.props.widget.params_initialized && this.props.widget.service_id) {
                            this.props.setParamsInitialized(true)
                            setTimeout(() => {
                                this.props.addServiceToOrder(this.props.widget.service_id)
                            }, 300)
                        }
                    }
                });
            }).catch(error => {
                if (error?.response?.status === 404)
                    this.props.history.push(this.props.home_path);
            });
        } else {
            if (!this.props.categories.ids.length)
                this.props.onCategoriesSelect(0);

            this.props.getServices().then(() => {
                if (!this.unmounted) {
                    this.setState({
                        loading: false,
                    });

                    if (this.props.widget.client_web && !this.props.widget.params_initialized && this.props.widget.service_id) {
                        this.props.setParamsInitialized(true)
                        setTimeout(() => {
                            this.props.addServiceToOrder(this.props.widget.service_id)
                        }, 300)
                    }
                }
            }).catch(error => {
                if (error?.response?.status === 404)
                    this.props.history.push(this.props.home_path);
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.unmounted = true;
    }

    render() {
        if ((this.state.loading || !this.props.order.services_loaded) && !this.props.maintenance && !this.props.salon_group_404)
            return <Loading/>;

        if (this.props.errorCode)
            return <Redirect to={this.props.error_path}/>;

        if (!this.props.order.services.length)
            return null;

        return (
            <ServiceItems items={this.props.order.services}/>
        );
    }
}

Services.defaultProps = {};

const mapStateToProps = state => {
    return {
        maintenance: state.location_lock.maintenance,
        errorCode: state.booking.errorCode,
        error_path: state.routes.paths.error,
        categories: state.categories,
        home_path: state.routes.paths.home,
        order: state.order,
        settings: state.salon_location.group_settings,
        widget: state.widget,
    };
};

const TranslatedServices = withTranslation(['services'])(Services);

export default connect(mapStateToProps, {
    setTransGroup,
    setSalonName,
    onSearch,
    getServices,
    getCategories,
    setActiveProgress,
    onCategoriesSelect,
    setParamsInitialized,
    addServiceToOrder
})(TranslatedServices);
