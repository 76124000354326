import React from 'react';
import ReactPageComponent, {connect} from '../components/ReactPageComponent';
import {withTranslation} from 'react-i18next';
import {setActiveProgress, setTransGroup} from '../actions/userInterface';
import {onSearch} from '../actions/search';
import {getCategories, onCategoriesSelect} from '../actions/categories';
import CategoryItems from '../components/CategoryItems';
import Loading from '../components/Loading';
import {Redirect} from 'react-router-dom';
import {setBookingErrorCode} from '../actions/booking';
import {slugChange} from '../actions/routes';
import {setParamsInitialized} from "../actions/widget";

class Categories extends ReactPageComponent {
    unmounted = false;
    scrollPosKey = 'Categories';
    state = {
        loading: true,
    };
    static times = 0;

    getCategories = () => {
        this.props.getCategories().then(() => {
            if (!this.unmounted) {
                this.setState({
                    loading: false,
                });

                if (this.props.widget.client_web && !this.props.widget.params_initialized && !this.props.widget.service_id && this.props.widget.category_id) {
                   this.props.setParamsInitialized(true)
                   this.props.onCategoriesSelect(this.props.widget.category_id)
               }
            }
        }).catch(error => {
            ++Categories.times;
            if (error?.response?.status === 404 && Categories.times < 2) {
                this.props.slugChange('');

                this.props.history.push(this.props.home_path);
            }
        });
    };

    componentDidMount() {
        super.componentDidMount();

        this.props.setTransGroup('categories');
        this.props.setActiveProgress(1);
        this.props.onSearch('');

        if (this.props.salons_loaded && this.props.salons.length)
            this.getCategories();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);

        if (this.props.salons_loaded && !prevProps.salons_loaded && this.props.salons.length && !this.props.categories.items_loaded)
            this.getCategories();

        if (prevState.loading && !this.state.loading && this.props.settings['my_salon_group.services.show.categories'] && !this.props.categories.services_total)
            this.props.setBookingErrorCode(102);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.unmounted = true;
    }

    render() {
        if ((this.state.loading || !this.props.categories.items_loaded) && !this.props.maintenance && !this.props.salon_group_404)
            return <Loading/>;

        if (!this.props.settings['my_salon_group.services.show.categories'])
            return <Redirect to={this.props.services_path}/>;

        if (this.props.errorCode)
            return <Redirect to={this.props.error_path}/>;

        if (this.props.widget.client_web && !this.props.widget.params_initialized && this.props.widget.service_id) {
            return <Redirect to={this.props.services_path}/>;
        }

        return (
            <CategoryItems items={this.props.categories.items} services_total={this.props.categories.services_total}/>
        );
    }
}

Categories.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        maintenance: state.location_lock.maintenance,
        salon_group_404: state.location_lock.salon_group_404,
        errorCode: state.booking.errorCode,
        error_path: state.routes.paths.error,
        settings: state.salon_location.group_settings,
        home_path: state.routes.paths.home,
        services_path: state.routes.paths.services,
        categories: state.categories,
        salons_loaded: state.loading.getLocations === false,
        salons: state.salon_location.items,
        widget: state.widget,
    };
};

const TranslatedCategories = withTranslation(['categories'])(Categories);

export default connect(mapStateToProps, {
    slugChange,
    setBookingErrorCode,
    setTransGroup,
    onSearch,
    setActiveProgress,
    getCategories,
    setParamsInitialized,
    onCategoriesSelect
})(TranslatedCategories);
