import React, {Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import ReactPageComponent, {connect} from '../components/ReactPageComponent';
import {withTranslation} from 'react-i18next';
import {setTransGroup} from '../actions/userInterface';
import {startNewBooking} from '../actions/booking';
import style from '../scss/components/Booking.module.scss';
import {ReactComponent as TimeErrorSvg} from '../assets/svg/time-error.svg';
import {ReactComponent as XLogo} from '../assets/svg/delete.svg';
import styleCancel from '../scss/components/Cancel.module.scss';

class Error extends ReactPageComponent {
    scrollPosKey = 'Error';

    componentDidMount() {
        super.componentDidMount();

        this.props.setTransGroup('error');
    }

    static renderGeneralErrorImg() {
        return (
            <Fragment>
                <img src={require('../assets/images/general-error.png')}
                     alt=""
                     srcSet={require('../assets/images/general-error@2x.png') + ' 2x, ' + require('../assets/images/general-error@3x.png') + ' 3x'}/>
            </Fragment>
        );
    }

    renderSvg() {
        return this.props.errorCode === 100 ? Error.renderGeneralErrorImg() : <TimeErrorSvg/>;
    }

    renderHeader() {

        const {t, errorCode} = this.props;

        switch (errorCode) {
            case 101:
                return t('time_taken');
            case 102:
                return t('online_booking');
            case 503:
                return t('maintenance');
            default:
                return t('general');
        }
    }

    renderHeaderInfo() {

        const {t, errorCode} = this.props;

        switch (errorCode) {
            case 101:
                return t('time_taken_info');
            case 102:
                return t('online_booking_info');
            case 503:
                return t('maintenance_info');
            default:
                return t('general_info');
        }
    }

    renderNotFoundView() {
        const {t} = this.props;
        return (
            <div className={styleCancel.Cancel}>
                <div className={styleCancel.Image}>
                    <div className={styleCancel.Icon}>
                        <XLogo/>
                    </div>
                </div>
                <div className={styleCancel.Header}>
                    {t('not_found')}
                </div>
                <div className={styleCancel.SubHeader}>
                    {t('not_found_info')}
                </div>
            </div>
        );
    }

    render() {
        if (!this.props.errorCode)
            return <Redirect to={'/'}/>;

        const {t} = this.props;

        if (this.props.errorCode === 404) {
            return this.renderNotFoundView()
        }

        return (
            <div className={style.Booking}>
                <div className={style.Image + ' ' + style.Error}>
                    <div className={style.Icon}>
                        {this.renderSvg()}
                    </div>
                </div>
                <div className={style.Header + ' ' + style.Error} dangerouslySetInnerHTML={{__html: this.renderHeader()}}/>
                <div className={style.SubHeader + ' ' + style.Error} dangerouslySetInnerHTML={{__html: this.renderHeaderInfo()}}/>
                <div className={style.Buttons}>
                    <div className={style.ActionButton} onClick={() => this.props.startNewBooking()}>
                        <span>{t('general:try_again')}</span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        errorCode: state.booking.errorCode,
    };
};

const TranslatedError = withTranslation(['error', 'general'])(Error);

export default connect(mapStateToProps, {
    setTransGroup,
    startNewBooking,
})(TranslatedError);
