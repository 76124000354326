export const WIDGET_TOGGLE = 'WIDGET_TOGGLE'
export const SIZE_CHANGED = 'SIZE_CHANGED'
export const ON_SEARCH = 'ON_SEARCH'
export const ON_LOCATION_CHANGE = 'ON_LOCATION_CHANGE'
export const ON_CATEGORIES_SELECT = 'ON_CATEGORIES_SELECT'
export const ON_CATEGORIES_DESELECT = 'ON_CATEGORIES_DESELECT'
export const LANGUAGE_SWITCH_TOGGLE = 'LANGUAGE_SWITCH_TOGGLE'
export const ON_TRANS_GROUP_CHANGE = 'ON_TRANS_GROUP_CHANGE'
export const ON_SALON_CHANGE = 'ON_SALON_CHANGE'
export const ON_PROGRESS_CHANGE = 'ON_PROGRESS_CHANGE'
export const ON_PROGRESS_ACTIVE_CHANGE = 'ON_PROGRESS_ACTIVE_CHANGE'
export const ADD_TO_ORDER = 'ADD_TO_ORDER'
export const REMOVE_FROM_ORDER = 'REMOVE_FROM_ORDER'
export const CLEAR_ORDER = 'CLEAR_ORDER'
export const TOGGLE_CONTAINER_SCROLL = 'TOGGLE_CONTAINER_SCROLL'
export const CONFIRM_REQUIRED = 'CONFIRM_REQUIRED'
export const SCROLL_POS_CHANGE = 'SCROLL_POS_CHANGE'
export const IDENTIFY_SALON = 'IDENTIFY_SALON'
export const IDENTIFY_SALON_BY_DOMAIN = 'IDENTIFY_SALON_BY_DOMAIN'
export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_SERVICES = 'GET_SERVICES'
export const RESET_STATE = 'RESET_STATE'
export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'
export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const ADD_EMPLOYEE_TO_ORDER = 'ADD_EMPLOYEE_TO_ORDER'
export const GET_EMPLOYEE_SCHEDULE = 'GET_EMPLOYEE_SCHEDULE'
export const ON_SCHEDULE_DATE_SELECT = 'ON_SCHEDULE_DATE_SELECT'
export const ON_ACTIVE_DATE_CHANGE = 'ON_ACTIVE_DATE_CHANGE'
export const ADD_DATETIME_TO_ORDER = 'ADD_DATETIME_TO_ORDER'
export const SET_NEW_WEEK_DATES = 'SET_NEW_WEEK_DATES'
export const ON_OVERLAY_LOADING = 'ON_OVERLAY_LOADING'
export const SET_SCHEDULE_VIEW_ACTIVE = 'SET_SCHEDULE_VIEW_ACTIVE'
export const SET_SCHEDULE_EDIT_ACTIVE = 'SET_SCHEDULE_EDIT_ACTIVE'
export const ON_SUBMITTING_BOOKING = 'ON_SUBMITTING_BOOKING'
export const ON_BOOKING_CONFIRM_SUCCESS = 'ON_BOOKING_CONFIRM_SUCCESS'
export const ON_BOOKING_CONFIRM_ERROR = 'ON_BOOKING_CONFIRM_ERROR'
export const ON_START_NEW_BOOKING = 'ON_START_NEW_BOOKING'
export const ON_SET_ERROR_CODE = 'ON_SET_ERROR_CODE'
export const LOADING = 'LOADING'
export const SET_LANGUAGES = 'SET_LANGUAGES'
export const RESET_SCHEDULE = 'RESET_SCHEDULE'
export const ON_GROUP_SETTINGS_UPDATE = 'ON_GROUP_SETTINGS_UPDATE'
export const ON_BOOKING_CONFIRM_FIELDS_ERROR = 'ON_BOOKING_CONFIRM_FIELDS_ERROR'
export const LOCATIONS_LOCK = 'LOCATIONS_LOCK'
export const GET_CANCEL_BOOKING = 'GET_CANCEL_BOOKING'
export const ON_CANCELLING_BOOKING = 'ON_CANCELLING_BOOKING'
export const ON_CANCEL_BOOKING_SUCCESS = 'ON_CANCEL_BOOKING_SUCCESS'
export const ON_SLUG_CHANGE = 'ON_SLUG_CHANGE'
export const ON_LANGUAGE_CHANGE = 'ON_LANGUAGE_CHANGE'
export const SET_WIDGET_LANGUAGE = 'SET_WIDGET_LANGUAGE'
export const ON_REGISTER_PERSIST_TIMESTAMP = 'ON_REGISTER_PERSIST_TIMESTAMP'
export const ON_MAINTENANCE_MODE = 'ON_MAINTENANCE_MODE'
export const ON_SALON_GROUP_NOT_FOUND = 'ON_SALON_GROUP_NOT_FOUND'
export const ON_CLEAR_ERROR_CODES = 'ON_CLEAR_ERROR_CODES'
export const ON_PATH_CHANGE = 'ON_PATH_CHANGE'
export const POST_SALON_CODE = 'POST_SALON_CODE'
export const POST_EMPLOYEE_CODE = 'POST_EMPLOYEE_CODE'
export const SET_SALON_CODE_NEXT_TRY = 'SET_SALON_CODE_NEXT_TRY'
export const SET_EMPLOYEE_CODE_NEXT_TRY = 'SET_EMPLOYEE_CODE_NEXT_TRY'
export const SET_CODE_EMPLOYEE_ID = 'SET_CODE_EMPLOYEE_ID'
export const EMPLOYEE_CODE_IS_REQUIRED = 'EMPLOYEE_CODE_IS_REQUIRED'
export const SALON_CODE_IS_REQUIRED = 'SALON_CODE_IS_REQUIRED'
export const RESET_SALON_CODE = 'RESET_SALON_CODE'
export const WIDGET_SET_PARAMS = 'WIDGET_SET_PARAMS'
export const PARAMS_INITIALIZED = 'PARAMS_INITIALIZED'
