import {LOCATIONS_LOCK, ON_CLEAR_ERROR_CODES, ON_MAINTENANCE_MODE, ON_SALON_GROUP_NOT_FOUND} from '../actions/type'

const INITIAL_STATE = {
    maintenance: false,
    request_locked: false,
    salon_group_404: false,
}

const locationLockReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOCATIONS_LOCK:
            return {
                ...state,
                request_locked: action.payload,
            }
        case ON_MAINTENANCE_MODE:
            return {
                ...state,
                maintenance: action.payload,
            }
        case ON_SALON_GROUP_NOT_FOUND:
            return {
                ...state,
                salon_group_404: action.payload,
            }
        case ON_CLEAR_ERROR_CODES:
            return {
                ...state,
                maintenance: false,
                salon_group_404: false,
            }
        default:
            return state
    }
}

export default locationLockReducer
