import {
    GET_LOCATIONS,
    LOCATIONS_LOCK,
    ON_CLEAR_ERROR_CODES,
    ON_GROUP_SETTINGS_UPDATE,
    ON_LOCATION_CHANGE,
    ON_SET_ERROR_CODE,
    SET_LANGUAGES,
} from './type'
import salonlife from '../api/salonlife'
import {loading} from './loading'
import getApiPath from '../helpers/getApiPath'
import {apiDomain, getSalonSlug, isWeb} from '../env'
import {changeLanguage, salonGroupNotFound, setSalonName} from './userInterface'
import i18n from '../trans/i18n'
import i18n_options, {setDefaultLanguage, setPreloadLanguages} from '../trans/i18n_options'
import {slugChange} from './routes'
import {onCategoriesSelect} from './categories'
import {cancelTheRequest, registerTheRequest, registerTimeout} from '../helpers/axiosCancel'
import store from "../store";

export const onChange = (value, items) => {
    return (dispatch, getState) => {
        let selectedLocationIdentifier, itemKey

        if (isNaN(value)) {
            itemKey = 'slug'
            selectedLocationIdentifier = String(value)
        } else {
            itemKey = 'id'
            selectedLocationIdentifier = parseInt(value)
        }

        if (!items)
            items = getState().salon_location.items

        const selectedLocation = items.filter(item => {
            return item[itemKey] === selectedLocationIdentifier
        })[0]

        if (isWeb) {
            const slug = selectedLocation?.slug

            slugChange(slug)(dispatch, getState)
        }

        if (!getState().salon_location.group_settings['my_salon_group.services.show.categories'])
            onCategoriesSelect(0)(dispatch, getState)

        dispatch({
            type: ON_LOCATION_CHANGE,
            payload: {
                selectedLocationIdentifier: value,
                selectedLocation: selectedLocation,
            },
        })
    }
}

export const updateGroupSettings = data => {
    return async dispatch => {
        dispatch({
            type: ON_GROUP_SETTINGS_UPDATE,
            payload: data,
        })
    }
}

export const getLocations = () => {
    const uniqueKey = 'getLocations'
    cancelTheRequest(uniqueKey)

    return async (dispatch, getState) => {
        const state = getState()

        if (!state.salon_location.items_loaded)
            loading(uniqueKey)(dispatch, getState)

        if (state.location_lock.request_locked)
            return

        dispatch({
            type: LOCATIONS_LOCK,
            payload: true,
        })

        dispatch({
            type: ON_CLEAR_ERROR_CODES
        })

        if (window.hasOriginalIndex)
            document.getElementById('meta-manifest-href').href = apiDomain + getApiPath(state.routes, state.widget) + '/manifest.json'

        return await salonlife.get(getApiPath(state.routes, state.widget) + '/locations', {
            cancelToken: registerTheRequest(uniqueKey),
        }).then(response => {
            if (!state.languages.languages_loaded) {
                const selectedLanguage = state.ui.selected_language
                const languages = []
                Object.keys(response.data.languages).forEach(key => {
                    languages.push(key)
                })

                let language = response.data.primary_language
                let queryLanguage = ''
                try {
                    const queryParams = new URLSearchParams(window.location.search)
                    queryLanguage = queryParams.get('lang')
                } catch (err) {
                }

                if (state.languages.widget_language && languages.indexOf(state.languages.widget_language) !== -1)
                    language = state.languages.widget_language
                else if (queryLanguage && languages.indexOf(queryLanguage) !== -1)
                    language = queryLanguage
                else if (selectedLanguage && languages.indexOf(selectedLanguage) !== -1)
                    language = selectedLanguage

                setPreloadLanguages(languages)
                setDefaultLanguage(language)
                changeLanguage(language)
                i18n.init(i18n_options(state.widget.identifier || state.widget.group_slug))
                document.documentElement.lang = language
            }

            if (window.hasOriginalIndex && isWeb) {
                document.getElementById('meta-theme-color').content = response.data?.background?.color ?? '#7367f9'
                document.title = response.data?.title ?? 'SalonLife'
            }

            if (isWeb && !response.data.data.length)
                slugChange('')(dispatch, getState)

            dispatch({
                type: SET_LANGUAGES,
                payload: {
                    primary_language: response.data.primary_language,
                    languages: response.data.languages,
                },
            })

            dispatch({
                type: GET_LOCATIONS,
                payload: {
                    title: response.data.title,
                    data: response.data.data,
                    group_settings: response.data.settings,
                    background: response.data.background,
                },
            })

            setTimeout(() => {
                dispatch({
                    type: LOCATIONS_LOCK,
                    payload: false,
                })
            }, 60000)

            const salon_slug = getSalonSlug()
            if (salon_slug && isWeb) {
                onChange(salon_slug, response.data.data)(dispatch, getState)
                setSalonName(getState().salon_location?.selected_location?.name ?? null)(dispatch, getState)
            }

            if (getState().widget.client_web) {
                if (getState().widget.salon_id) {
                    onChange(getState().widget.salon_id, response.data.data)(dispatch, getState)
                }
            }

            if (!response.data.data.length)
                dispatch({
                    type: ON_SET_ERROR_CODE,
                    payload: 102,
                })

            loading(uniqueKey, false)(dispatch, getState)

            return response
        }).catch(error => {
            if (isWeb && error && error?.response?.status === 404) {
                //window.location.href = mainDomain
                salonGroupNotFound(true)(dispatch, store.getState)
            }
            else if (error && error.response && error?.response?.status !== 404)
                registerTimeout(uniqueKey, () => {
                    getLocations()(dispatch, getState)
                }, 60000)

            throw error
        })
    }
}
